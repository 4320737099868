<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex justify-start align-center">
        <app-bar-route />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-start justify-md-end align-center"
      >
        <app-bar-btn />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppBarList",
  components: {
    AppBarRoute: () => import("../AppBarComponents/AppBarRoute"),
    AppBarBtn: () => import("../AppBarComponents/AppBarBtn"),
  },
};
</script>
